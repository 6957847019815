<template>
  <div class="banner">
    <warning-tip code="SPTSLMCX"></warning-tip>

    <main-top
      title="类目查询"
      desc="在线查询天猫、淘宝商品宝贝类目属性。再也不用去问别人怎么查看淘宝产品类目了"
      spend="SPTSLMCX"
    >
      <!-- 使用搜索框组件 -->
      <InputView
          :extractProductId="true"
          :CanYouClick="CanYouClick"
          placeholder-name="请输入您的宝贝链接或宝贝ID"
          @sendSearchName="getSearchName"
        ></InputView>
    </main-top>

    <!-- 展示区域 -->
    <bordure-row :title="bannerTitle" shadow style="margin-bottom: 30px">
      <div class="bannerContentIn">
        <div class="img_floor" v-if="goodsEntity.picUrl == ''"></div>
        <img
          v-else
          :src="goodsEntity.picUrl"
          width="100px"
          height="100px"
          alt="图片正在加载"
        />
        <div style="font-size: 12px">
          <p>售价： {{ goodsEntity.price }} 元</p>
          <p>{{ goodsEntity.goodsName }}</p>
        </div>
      </div>
    </bordure-row>
    <!-- 使用搜索框组件 -->
    <Introduce :introduce="introduce"></Introduce>
  </div>
</template>

<script>
import { getCategory } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      CanYouClick: true,
      input: "",
      bannerTitle: "运动/瑜伽/健身/球迷用品=>>篮球=>>篮球 (模板)", //查询的类目
      goodsEntity: {
        cid: null,
        createTime: null,
        goodsId: 35689836013,
        goodsName:
          "斯伯丁篮球官方正品7号6小学生5儿童真皮牛皮手感耐磨NBA运动专用",
        id: null,
        nick: null,
        otherParams: null,
        picUrl:
          "//img.alicdn.com/imgextra/i1/1713424658/O1CN01D3HcKE1kHPVHu1HZ0_!!0-item_pic.jpg",
        price: "229.00",
      }, //查询的属性内容
      introduce: [
        {
          title: "有什么作用?",
          icon: "&#xe60a;",
          content: [
            "",
            "作为淘宝新手卖家，一般不太清楚要发布宝贝应该放入在什么类目里。通过我们这个工具，大家先查询同类热销宝贝的类目属性，然后再发布对应的类目里。",
            "准确的类目，不会被淘宝处罚，可以提高宝贝的流量。",
          ],
        },
      ],
    };
  },
  components: {
    InputView,
    Introduce,
  },
  methods: {
    //   子传父的搜索方法
    getSearchName(value) {
      this.$store.commit("alterLoadContent", "正在该类目的相关数据");

      this.goodsEntity = {
        cid: null,
        createTime: null,
        goodsId: "",
        goodsName: "",
        id: null,
        nick: null,
        otherParams: null,
        picUrl: "",
        price: "",
      };
      this.bannerTitle = "";
      this.CanYouClick = false; //不允许再点击搜索
      getCategory({
        itemid: value,
      })
        .then((data) => {
          this.bannerTitle = data.data.categoryName; //赋值查询的类目
          this.goodsEntity = data.data.goodsEntity; //赋值查询的属性内容
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true; //允许再次搜索
        });
    },
  },
};
</script>

<style lang="less" scoped>



/* 查询后的内容标题样式 */
.bannerTitle {
  padding-left: 20px;
  background-color: #e5e5e5;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
}

.bannerContentIn {
  padding: 17px 0 17px 20px;
  border: 1px solid #e5e5e5;
  width: 100%;
  /* height: 300px; */
  box-sizing: border-box;
  display: flex;
  line-height: 28px;

  img {
    display: block;
    margin-right: 30px;
  }
}
.img_floor {
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, #ceefff, #7bd5ff);
  margin-right: 50px;
}
</style>